// extracted by mini-css-extract-plugin
export var btn = "hamburger-module--btn--Mipk4";
export var closeIcon = "hamburger-module--closeIcon--6jLRH";
export var contactMailWrapper = "hamburger-module--contactMailWrapper--dA64u";
export var contactStorySpan = "hamburger-module--contactStorySpan--bi7BX";
export var contactStoryWrapper = "hamburger-module--contactStoryWrapper--OKOGM";
export var dataContainer = "hamburger-module--dataContainer--FMXM7";
export var flex = "hamburger-module--flex--Z8KT6";
export var form = "hamburger-module--form--CsXxs";
export var formControl = "hamburger-module--formControl--+y6Wk";
export var iconClass = "hamburger-module--iconClass--GERbk";
export var notifyWrapperText = "hamburger-module--notifyWrapperText--55gl8";
export var profileCard = "hamburger-module--profileCard--zodE4";
export var socialStyle = "hamburger-module--socialStyle--aXGzS";
export var subscribeWrapper = "hamburger-module--subscribeWrapper--autMQ";