export const subscribeForm = (homepage, selectedLang, messages) => {
  let width = "100%";
  let buttonBackground = "#51566f";
  let buttonColor = "#bdbfc8";
  let language = selectedLang ? selectedLang : "en";
  if (homepage) {
    width = "80%";
    buttonBackground = "#313131";
    buttonColor = "#ffffff";
  }

  return {
    __html: `
    <style>
    #mc_embed_signup{
        background: unset !important;
        width: ${width} !important;
        margin-top: 30px !important;
        font-family: "Times New Roman", Times, serif !important;
        display: inline-block !important;
    }

    #mc_embed_signup form {
        padding: 0px !important
    }
    #mce-EMAIL {
      border: 1px solid #51566f !important;
      width: 100% !important;
      padding: 7px !important;
      font-size: 16px !important;
    }
    
    #mc-embedded-subscribe {
      display: inline-block !important;
      padding: 10px 30px !important;
      cursor: pointer !important;
      background: ${buttonBackground} !important;
      color: ${buttonColor} !important;
      border: none !important;
      /* border-radius: 5px; */
      width: 100% !important;
      line-height: 0px !important;
    }
    .mc-field-group {
      width: 100% !important
    }

    @media screen and (max-width: 500px) {
        #mc_embed_signup{
            width: 100% !important;
        }
    }
  
    </style>


    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
    <style type="text/css">
        #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
        <form action="https://everyoneisgoodatsomething.us1.list-manage.com/subscribe/post?u=ab5e7609c45229ca3da2cc65e&amp;id=40861409c2" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
            <div class="mc-field-group">
                <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="${messages.enterEmail}">
            </div>
            <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display:none"></div>
                <div class="response" id="mce-success-response" style="display:none"></div>
            </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ab5e7609c45229ca3da2cc65e_40861409c2" tabindex="-1" value=""></div>
            <div class="clear"><input type="submit" value="${messages.subscribe}" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
            </div>
        </form>
    </div>
    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
      `,
  };
};
